import React, { useEffect, useState } from 'react';
import { Table, Header } from 'semantic-ui-react';
import { API, showError } from '../helpers';

const ModelPriceList = () => {
    const [modelPrices, setModelPrices] = useState([]);

    useEffect(() => {
        loadModelPrices();
    }, []);

    const loadModelPrices = async () => {
        // Fetch ModelRatio from API
        const res = await API.get('/api/option/');
        const { success, message, data } = res.data;
        if (success) {
            // Find the ModelRatio option and parse it
            let modelRatioOption = data.find((item) => item.key === 'ModelRatio');
            let modelRatio = {};
            if (modelRatioOption) {
                try {
                    modelRatio = JSON.parse(modelRatioOption.value);
                } catch (e) {
                    showError('模型倍率的 JSON 格式不正确');
                    return;
                }
            }

            // Fetch available models from API
            const resModels = await API.get('/api/user/available_models');
            const { success: successModels, message: messageModels, data: models } = resModels.data;
            if (successModels) {
                // For each model, find its rate
                let modelPricesData = models.map((model) => {
                    let rate = modelRatio[model] || 1; // Default rate is 1 if not specified
                    let price = calculatePrice(model, rate);
                    return { model, rate, price };
                });
                setModelPrices(modelPricesData);
            } else {
                showError(messageModels);
            }
        } else {
            showError(message);
        }
    };

    const getBasePrice = (model) => {
        return 2; // Return '-' if not found
    };

    const calculatePrice = (model, rate) => {
        const basePrice = getBasePrice(model);
        if (basePrice === '-') return '-';
        const price = basePrice * rate;
        return price.toFixed(4); // Fix to 6 decimal places
    };

    return (
        <div>
            <Header as="h3">THUNLP模型计费列表</Header>
            <Header as="h4">(以下为平均计价，实际输入输出tokens价格会有不同，几家不同供应商的同一模型价格也不同，以下为折中值。具体请求消耗额度以日志界面扣费为准)</Header>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>模型名称</Table.HeaderCell>
                        <Table.HeaderCell>倍率</Table.HeaderCell>
                        <Table.HeaderCell>输入输出平均计费价格 (每 1M tokens)</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {modelPrices.map(({ model, rate, price }) => (
                        <Table.Row key={model}>
                            <Table.Cell>{model}</Table.Cell>
                            <Table.Cell>{rate}</Table.Cell>
                            <Table.Cell>{price === '-' ? '-' : `$${price}`}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default ModelPriceList;